import React from "react"
import LocalImage from "../components/LocalImage"
import styled from "@emotion/styled"
import SiteWrap from "../components/layout/SiteWrap"
import Container from "../components/layout/Container"
import TextContainer from "../components/layout/TextContainer"
import InstagramSection from "../components/features/Instagram/Section"
import { graphql } from "gatsby"
import Button from "../components/Button"
import {
  breakpointAboveLarge,
  breakpointBelowLarge,
  breakpointBelowSmall,
} from "../styles/const"

const HeroContainer = styled.div`
  display: grid;
  grid-gap: 4em;
  grid-auto-flow: row dense;
  ${breakpointBelowLarge} {
    overflow: hidden;
    margin-bottom: 0;
    padding-bottom: 4em;
  }
  ${breakpointAboveLarge} {
    align-items: center;
    grid-template-columns: 1.2fr 1fr;
    grid-gap: 6em;
  }
  ${breakpointBelowSmall} {
    margin-bottom: -4em;
  }
  p {
    font-size: 2.8em;
    line-height: 1.6;
    margin-bottom: 0;
    ${breakpointBelowSmall} {
      font-size: 1.8em;
    }
  }
  .right {
    z-index: 11;
    ${breakpointAboveLarge} {
      grid-column: 2;
    }
  }
`
const ButtonContainer = styled.div`
  text-align: center;
`

export const imageData = graphql`
  query {
    file(relativePath: { eq: "johnny-tuosto.jpg" }) {
      childImageSharp {
        # Specify a fluid image and fragment
        # The default maxWidth is 800 pixels
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({ data }) => (
  <SiteWrap>
    <Container fadeIn={true}>
      <HeroContainer>
        <div className="right">
          <p>
            Hey there, and thanks for dropping by. My name is John Tuosto and
            I’m a New York bred, Italian fed drummer.
          </p>
        </div>
        <div className="left">
          <LocalImage fluid={data.file.childImageSharp.fluid} />
        </div>
      </HeroContainer>
    </Container>
    <TextContainer>
      <p>
        I made my way out to Los Angeles to build my career as a musician. I’ve
        dedicated my life to professionally studying and playing drums and music
        at large.
      </p>
      <p>
        Whether you’re looking for a session drummer, in need of someone to
        write drum parts and help produce, searching for a drummer to bring on
        tour or for one-offs, or maybe you’re a drummer yourself in need of a
        tech, I am ready for the gig. While you’re here, check out some of my
        work and feel free to drop a line so we can be in touch. I look forward
        to working with you!
      </p>
      <ButtonContainer>
        <Button url="mailto:jtuosto15@gmail.com">Reach Out</Button>
      </ButtonContainer>
    </TextContainer>
    <TextContainer centerText={true}>
      <h2>Some of my Work</h2>
    </TextContainer>
    <InstagramSection limit={8} moreButton={true} />
  </SiteWrap>
)
